import HttpClient from "../HttpClient";
import { RefundTypeEnum } from "../enums/RefundTypeEnum";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import moment from "moment";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import DeniedCookInterface from "../interfaces/Cook/DeniedCookInterface";
import {PaymentStatusEnum} from "../enums/PaymentStatusEnum";


export class OneMealService {

  processDateRangeFilter(filters: any) {
    let where;
    if (filters.startDate) {
        where = {
            [Op.gte.toString()]: moment(filters.startDate).toDate()
        }
        delete filters.startDate
    }
    if (filters.endDate) {
        if (where) {
            where = {
                ...where,
                [Op.lte.toString()]: moment(filters.endDate).toDate()
            }
        } else {
            where = {
                [Op.lte.toString()]: moment(filters.endDate).toDate()
            }
        }
        delete filters.endDate
    }
    return {date: where};
}

  cookAcceptedFilter(filters: any) {
    let numberOfCookAccepted;
    if (filters?.numberOfCookAccepted) {
      numberOfCookAccepted = {
        [Op.gte.toString()]: filters?.numberOfCookAccepted,
      }
    }
    return {numberOfCookAccepted};
  }
  paymentFilter(filters: any) {
    if(!filters?.paymentStatus) {
      return {};
    }
    if(filters?.paymentStatus === PaymentStatusEnum.NOT_PAID){
      return {
          paymentStatus: {[Op.eq.toString()]: PaymentStatusEnum.NOT_PAID },
          hasOptedPayAfterService: false,
      }
    } else if(filters?.paymentStatus === PaymentStatusEnum.PAID){
      return {
        [Op.or.toString()]: [
            { paymentStatus: {[Op.eq.toString()]: PaymentStatusEnum.NOT_PAID }, hasOptedPayAfterService: true},
            { paymentStatus: {[Op.eq.toString()]: PaymentStatusEnum.PAID }}
        ]
      }
    } else {
      return {
        paymentStatus:  {[Op.eq]: filters?.paymentStatus }
      }
    }
  }

  async getAllOneMealService(
    limit: number,
    offset: number,
    filters: any,
    sort: any,
    otherFilters: any = {},
  ) {
    let nullFilters: string[] = [];
    let notNullFilters: string[] = [];
    if (filters.omsType === 'Normal OMS') {
        nullFilters.push('userLeaveMarkedId')
    }
    if (filters.omsType === 'TR OMS') {
        notNullFilters.push('userLeaveMarkedId')
    }
    if(filters.preCheckedInAt==="YES"){
      notNullFilters.push('preCheckedInAt')
    }
    if(filters.preCheckedInAt==="NO"){
      nullFilters.push('preCheckedInAt')
    }
    let filter = ConstructSequelizeFilters(filters, ['customerName', 'customerPhoneNumber', 'cookName', 'cookPhoneNumber', 'trialOwnerName', 'sector'], ['id', 'cookId', 'status', 'date', 'time', 'cityId', 'areaId', 'cancelledBy', 'cookAcknowledged', 'numberOfCookAllocated', 'createdBy', 'createdByType', 'isCheckinValid'],nullFilters, notNullFilters);
    let customFilters = this.cookAcceptedFilter(filters);
    let paymentFilters = this.paymentFilter(filters);
    filters = {
      ...filter,
      ...customFilters,
      ...otherFilters,
      ...paymentFilters,
    }
    let data = await HttpClient.get("/centaurus/one-meal-service", {
      params: {
        limit,
        offset,
        filters,
        sort,
      },
    });
    return {
      data: data?.data?.data,
      total: data?.data?.count,
    };
  }

  async createOneMealService(payload: any) {
    let houseId = payload.houseId
    delete payload.houseId
    let data = await HttpClient.post(`/centaurus/one-meal-service/houses/${houseId}`, payload);
    return data?.data;
  }

  async editOneMealService(serviceId: number, payload: {houseId: number, date: string, time: string, totalPeople: number, genderPreference: string}){
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${serviceId}`, payload);
    return data?.data;
  }

  async getOneMealService(serviceId: number) {
    let data = await HttpClient.get(`/centaurus/one-meal-service/${serviceId}`);
    return data;
  }

  async cancelOneMealService(serviceId: number, payload: any){
    let data = await HttpClient.delete(`/centaurus/one-meal-service/${serviceId}`,{data: payload})
    return data;
  }

  async preCheckIn(cookId:number,serviceId:number){
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${serviceId}/cook/${cookId}/pre-checkin`)
    return data;
  }

  async assignCookToOneMealService(id: number,payload: { cookId: number; oneMealServiceCookCut: string; allocationNotes: string;}) {
    let data = await HttpClient.post(`/centaurus/one-meal-service/${id}/assign`, payload);
    return data;
  }

  async rescheduleOneMealService(serviceId: number,payload: any) {
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${serviceId}/reschedule`,payload);
    return data;
  }

  async cashCollected(serviceId:number){
    const payload = {
      "isCashCollected": true
    }
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${serviceId}/collect-cash`,payload);
    return data?.data;
  }

  async acceptOneMealService(serviceId: number, payload: { accepted: boolean; reason: string }) {
    let data = await HttpClient.post(`/centaurus/one-meal-service/${serviceId}/approval`, payload);
    return data;
  }

  async changeOneMealOwner(serviceId: number, payload: { ownerId: number }) {
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${serviceId}/trial-owner`,payload);
    return data;
  }

  async changeCookAllocator(serviceId: number, payload:  {allocatorId: number}){
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${serviceId}/trial-allocator`, payload)
    return data;
  }

  async patchLocality(serviceId: number, payload: {cityId: number, areaId: number, localityId: number}){
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${serviceId}/locality`, payload)
    return data;
  }

  async addManualLeadToOneMealService(serviceId: number, payload: {cookId: number, cookPreference: number}){
    let data = await HttpClient.post(`/centaurus/one-meal-service/${serviceId}/lead`, payload)
    return data;
  }

  async getAcceptedLeadForOneMeal(serviceId: number,  limit: number, offset: number, sort: any){
    let data = await HttpClient.get(`/centaurus/one-meal-service/${serviceId}/accepted-leads`, {
      params: {
        limit,
        offset,
        sort,
    }
    })
    return data;
  }

  async getAllOneMealServiceForHouse(houseId: number, limit: number, offset: number, sort: {}, filters?: {}){
    let data: any = await HttpClient.get(`/centaurus/one-meal-service/houses/${houseId}`, {
      params: {
        limit,
        offset,
        sort,
        filters,
      }
    });
    return data?.data;
  }

  async getAllOneMealServiceForUserBundle(userBundleMappingId: number, limit: number, offset: number, sort: {}) {
    let filter = {"userBundleMappingId": userBundleMappingId}
    const filters = ConstructSequelizeFilters(filter,[],['userBundleMappingId']);
    let data: any = await HttpClient.get(`/centaurus/one-meal-service`, {
      params: {
        limit,
        offset,
        sort,
        filters
      }
    })

    return data?.data;
  }

  async getVendorPayoutForOneMeal(serviceId: number){
    let data = await HttpClient.get(`/centaurus/one-meal-service/${serviceId}/vendor-payout`)
    return data
  }

  async checkCookAvaibilityForOneMeal(cookId: number, oneMealServiceId: number){
    let data = await HttpClient.post(`/centaurus/one-meal-service/checkCookAvailability`,{
      oneMealServiceId,
      cookId
    })
    return data?.data;
  }

  async checkCookFromAllocationListForOneMeal(oneMealServiceId: number, cookId: number){
    let data = await HttpClient.post(`/centaurus/one-meal-service/is-auto-allocated-cook`, {
      oneMealServiceId,
      cookId
    })
    return data?.data;
  }

  async adminNotesForOneMeal(serviceId: number, payload: {adminNotes: string}){
    let data = await HttpClient.post(`/centaurus/one-meal-service/${serviceId}/admin-notes`, payload)
    return data?.data;
  }

  async addAllocationNotesForOneMeal(id: number, payload: {commentOrFeedback: string} ){
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${id}/allocation-comments`, payload)
    return data?.data
  }

  async addSupportCommentsForOneMeal(id: number, payload: {commentOrFeedback: string}){
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${id}/support-comments`, payload)
    return data?.data
  }

async addCxFeedbackForOneMeal(id: number, payload: {commentOrFeedback: string}){
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${id}/cx-feedback`, payload)
    return data?.data
  }

async addHcFeedbackForOneMeal(id: number, payload: {commentOrFeedback: string}){
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${id}/hc-feedback`, payload)
    return data?.data
  }

  async processRefundOneMealService(id: number, payload: {reason: string, refundType: RefundTypeEnum, amount?: string}){
    let data = await HttpClient.post(`/centaurus/one-meal-service/${id}/refund`, payload)
    return data?.data
  }

  async sendOneMealPaymentLink(id: number){
    let data = await HttpClient.post(`/centaurus/one-meal-service/${id}/send-payment-link`)
    return data?.data
  }

  async markAsOngoingForOneMeal(serviceId: number){
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${serviceId}/ongoing`)
    return data
  }

  async markAsFulfiledForOneMeal(serviceId: number){
    let data = await HttpClient.post(`/centaurus/one-meal-service/${serviceId}/fulfilled`)
    return data
  }

  async denyCook(serviceId: number, payload: {reason: string}) {
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${serviceId}/deny`, payload)
    return data?.data
  }

  async changeOneMealServiceTime(serviceId: number, payload: {time: string}) {
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${serviceId}/change-time`, payload)
    return data?.data;
  }

  async getDeniedCooksForOneMeal(serviceId: number): Promise<DeniedCookInterface[]>{
    let data = await HttpClient.get(`/centaurus/one-meal-service/${serviceId}/get-denied-cooks`)
    return data?.data;
  }

  async revertDeniedCook(serviceId: number, cookId: number){
    let data = await HttpClient.delete(`/centaurus/one-meal-service/${serviceId}/cook/${cookId}/revert-denial`)
    return data?.data;
  }

  async setContactedAt(serviceId: number, cookId: number, payload: any){
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${serviceId}/list-cook-contacted/${cookId}`, payload)
    return data?.data;
  }

  async penalizeCookForNotWearingTShirt(serviceId: number){
    let data = await HttpClient.post(`/centaurus/one-meal-service/${serviceId}/tshirt-panelty`)
    return data?.data;
  }

  async requestCookConveyance(cookId: number, payload: any) {
    let data = await HttpClient.patch(`/common/one-meal-service/${cookId}/request-cook-conveyance`, payload)
    return data?.data;
  }
  async getOmsCookingTime() {
    let data = await HttpClient.get(`/customers/one-meal-service/cooking-time`)
    return data?.data;
  }

  async updateOMSStatus(id: number, payload: any) {
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${id}/oms-status`, payload);
    return data?.data;
  }
  async updateOMSPaymentStatus(id: number, payload: any) {
    let data = await HttpClient.patch(`/centaurus/one-meal-service/${id}/oms-paymnet-status`, payload);
    return data?.data;
  }
  async checkConveynceEligibility(omsId:number,cookId:number):Promise<any>{
    const data = await HttpClient.get(`/centaurus/one-meal-service/${omsId}/cook/${cookId}/conveyance-eligibilty`);
    return data?.data;
  }

  async createRazaorPayOrder(omsId:number,couponId:number){
    const payload = {
      discountCouponId: couponId,
    }
    const data = await HttpClient.post(`/centaurus/one-meal-service/${omsId}/razorpay-order`,payload);
    return data?.data;
  }
  async getOmsPrice(payload:any){
    const data = await HttpClient.post(`/common/one-meal-service/calculate-price`,payload);
    return data?.data;
  }
  async changeAllowedDishes(omsId:number,payload:any){
    const data = await HttpClient.patch(`/centaurus/one-meal-service/${omsId}/patch-oms-allowed-dishes`,payload);
    return data?.data;
  }
  async changeMealMap(omsId:number ,payload:any){
    const data = await HttpClient.patch(`/centaurus/one-meal-service/${omsId}/meal-map`,payload);
    return data?.data;
  }
  async getDishByFoodCategory(
    limit: number,
    offset: number,
    filters: any,
    sort: any){
    let data = await HttpClient.get("cuisine/dishes", {
      params: {
        limit,
        offset,
        filters,
        sort
      },
    });
    return data?.data;
  }
}
