import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";
import moment from 'moment';

interface Props {
  data: any;
  open: boolean;
  callback: () => void;
}

const ChefOrderSummaryDialog = (props: Props) => {
  const [bartender, setBartender] = useState(0);
  const [waiter, setWaiter] = useState(0);


  useEffect(() => {
    if(!props?.data) {
      return;
    }
    props?.data?.orders?.map((eachOrder: any) => {
      if(eachOrder?.orderItems?.length !== 0){
        eachOrder?.orderItems.map((eachOrderItem: any) => {
          if(eachOrderItem?.serviceAddons?.id === 1){
            setBartender(prevState => prevState += Number(eachOrderItem?.quantity));
          }
          if(eachOrderItem?.serviceAddons?.id === 2){
            setWaiter(prevState => prevState += Number(eachOrderItem?.quantity));
          }
          return eachOrderItem;
        })
      }
      return eachOrder;
    })
  }, [props?.data])

  const message = `Dear ${props?.data?.customerName},

Thank you for completing the payment for your upcoming event with ChefKart! 🎉 Below is a summary of your event details:

Event Summary:
📅 Date: ${moment(props?.data?.date).format('Do MMMM YYYY')}
⏰ Time: ${props?.data?.time}
📍 Address: ${[props?.data?.house?.address, props?.data?.house?.localityName, props?.data?.house?.areaName, props?.data?.house?.cityName]?.filter((each) => each !== null).join(', ')}
🍽️ Menu: ${props?.data?.dishes?.map((eachDish: any) => eachDish?.dish?.name)?.join(', ') || 'N/A'}
👨‍👩‍👧‍👦 Number of Adults: ${props?.data?.noOfAdults}
👶 Number of Children: ${props?.data?.noOfChildren}
🍴 Number of Waiters: ${waiter || 0}
🍹 Number of Bartenders: ${bartender || 0}

Your chef will be sharing a consolidated ingredient list shortly. 📝

Arrival & Stay
⏰ Chef arrives 3-4 hours before the event.
⏳ Stays up to 2 hours after serving time.
💵 Extra stay: ₹500/hour (to be paid directly to the chef).

Ingredients & Utensils
🍳 Please arrange all ingredients and utensils beforehand.
🚫 Utensils are not provided by us.

Cleanliness
🧼 The chef will ensure the kitchen is tidy (clean countertops & stove).
🚫 Utensil washing is not included.

We are excited to be a part of your event and make it truly special! 💫 Enjoy the experience!

Warm regards,
Team ChefKart 👩‍🍳👨‍🍳`;

  const whatsAppMessage = `*Dear ${props?.data?.customerName}*,

Thank you for completing the payment for your upcoming event with ChefKart! 🎉 Below is a summary of your event details:

*Event Summary:*
📅 *Date:* ${moment(props?.data?.date).format('Do MMMM YYYY')}
⏰ *Time:* ${props?.data?.time}
📍 *Address:* ${[props?.data?.house?.address, props?.data?.house?.localityName, props?.data?.house?.areaName, props?.data?.house?.cityName]?.filter((each) => each !== null).join(', ')}
🍽️ *Menu:* ${props?.data?.dishes?.map((eachDish: any) => eachDish?.dish?.name)?.join(', ') || 'N/A'}
👨‍👩‍👧‍👦 *Number of Adults:* ${props?.data?.noOfAdults}
👶 *Number of Children:* ${props?.data?.noOfChildren}
🍴 *Number of Waiters:* ${waiter || 0}
🍹 *Number of Bartenders:* ${bartender || 0}

Your chef will be sharing a *consolidated ingredient list* shortly. 📝

*Arrival & Stay*
⏰ Chef arrives *3-4 hours* before the event.
⏳ Stays *up to 2 hours* after serving time.
💵 *Extra stay:* ₹500/hour (to be paid directly to the chef).

*Ingredients & Utensils*
🍳 Please arrange all *ingredients* and *utensils* beforehand.
🚫 *Utensils are not provided* by us.

*Cleanliness*
🧼 The chef will ensure the kitchen is tidy (clean countertops & stove).
🚫 *Utensil washing* is not included.

We are excited to be a part of your event and make it truly special! 💫 Enjoy the experience!

Warm regards,
*Team ChefKart 👩‍🍳👨‍🍳*`;

  const handleCopy = () => {
    navigator.clipboard.writeText(message)
        .then(() => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Order Summary Copied Successfully",
            type: CustomSnackbarTypes.SUCCESS,
          });
        });
  };
  const handleCopyForWhatsApp = () => {
    navigator.clipboard.writeText(whatsAppMessage)
        .then(() => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Order Summary Copied Successfully",
            type: CustomSnackbarTypes.SUCCESS,
          });
        });
  };

  const handleClose = () => {
    props.callback();
  };

  return (
      <Dialog
          onClose={handleClose}
          open={props.open}
          scroll={"paper"}
          maxWidth="xl"
      >
        <DialogTitle>
          <Typography fontWeight={'600'}>
            {"Order Summary"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography component="div" sx={{ whiteSpace: "pre-wrap", lineHeight: 1.8, fontWeight: "bold", marginBottom: 2 }}>
            {`Dear ${props?.data?.customerName},`}
          </Typography>
          <Typography component="div" sx={{ whiteSpace: "pre-wrap", lineHeight: 1.8, marginBottom: 2 }}>
            {`Thank you for completing the payment for your upcoming event with ChefKart! 🎉 Below is a summary of your event details:`}
          </Typography>
          <Typography component="div" sx={{ whiteSpace: "pre-wrap",fontWeight: "bold", lineHeight: 1.8 }}>
            {`Event Summary:`}
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            📅 <b>Date:</b> {moment(props?.data?.date).format('Do MMMM YYYY')}
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            ⏰ <b>Time:</b> {props?.data?.time}
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            📍 <b>Address:</b>{" "}
            {[
              props?.data?.house?.address,
              props?.data?.house?.localityName,
              props?.data?.house?.areaName,
              props?.data?.house?.cityName,
            ]
                .filter((each) => each !== null)
                .join(", ")}
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            🍽️ <b>Menu:</b>{" "}
            {props?.data?.dishes
                ?.map((eachDish: any) => eachDish?.dish?.name)
                ?.join(", ") || "N/A"}
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            👨‍👩‍👧‍👦 <b>Number of Adults:</b> {props?.data?.noOfAdults}
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            👶 <b>Number of Children:</b> {props?.data?.noOfChildren}
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            🍴 <b>Number of Waiters:</b> {waiter || 0}
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            🍹 <b>Number of Bartenders:</b> {bartender || 0}
          </Typography>
          {/*<Divider sx={{ my: 2 }} />*/}
          <Typography component="div" sx={{ whiteSpace: "pre-wrap", lineHeight: 1.8 }}>
            {`Your chef will be sharing a consolidated ingredient list shortly. 📝`}
          </Typography>
          <Typography component="div" sx={{ whiteSpace: "pre-wrap", lineHeight: 1.8, marginTop: 2 }}>
            <b>Arrival & Stay</b>
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            ⏰ Chef arrives <b>3-4 hours</b> before the event.
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            ⏳ Stays <b>up to 2 hours</b> after serving time.
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            💵 <b>Extra stay:</b> ₹500/hour (to be paid directly to the chef).
          </Typography>
          {/*<Divider sx={{ my: 2 }} />*/}
          <Typography component="div" sx={{ whiteSpace: "pre-wrap", lineHeight: 1.8 }}>
            <b>Ingredients & Utensils</b>
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            🍳 Please arrange all <b>ingredients</b> and <b>utensils</b> beforehand.
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            🚫 <b>Utensils are not provided</b> by us.
          </Typography>
          {/*<Divider sx={{ my: 2 }} />*/}
          <Typography component="div" sx={{ whiteSpace: "pre-wrap", lineHeight: 1.8 }}>
            <b>Cleanliness</b>
          </Typography>
          <Typography component="div" sx={{ marginBottom: 1 }}>
            🧼 The chef will ensure the kitchen is tidy (clean countertops & stove).
          </Typography>
          <Typography component="div" sx={{ marginBottom: 2 }}>
            🚫 <b>Utensil washing</b> is not included.
          </Typography>
          <Typography component="div" sx={{ whiteSpace: "pre-wrap", lineHeight: 1.8 }}>
            {`We are excited to be a part of your event and make it truly special! 💫 Enjoy the experience!`}
          </Typography>
          {/*<Divider sx={{ my: 2 }} />*/}
          <Typography component="div" sx={{ marginTop: 2 }}>
            Warm regards,
          </Typography>
          <Typography component="div" sx={{ fontWeight: "bold" }}>
            <b>Team ChefKart 👩‍🍳👨‍🍳</b>
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
              variant="contained"
              color={'success'}
              onClick={handleCopyForWhatsApp}
          >
            Copy For WhatsApp
          </Button>
          <Button
              variant="contained"
              onClick={handleCopy}
          >
            Copy Message
          </Button>
          <Button
              variant="contained"
              color={"error"}
              onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default ChefOrderSummaryDialog;
