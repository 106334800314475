import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, TextField,
  Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import ChefForPartyService from "../../services/ChefForPartyService";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "./CustomSnackBar";

interface Props {
  data: any;
  open: boolean;
  callback: () => void;
}

const chefForPartyService = new ChefForPartyService();

const CashCollectedDialog = (props: Props) => {
  const [amount, setAmount] = useState(0);

  const handleSubmit = () => {
    if (isNaN(amount)) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Please enter a valid number or a number greater than zero to continue",
        type: CustomSnackbarTypes.ERROR,
      });
      return
    }
    if (Number(amount) < 0) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Please enter a number greater than zero to continue",
        type: CustomSnackbarTypes.ERROR,
      });
      return
    }
    chefForPartyService.updatePartyAmountToBeCollected(props?.data?.id, {
      amount_to_be_collected: Number(amount),
    }).then(() => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Amount Updated Successfully",
        type: CustomSnackbarTypes.SUCCESS,
      });
      handleClose();
    })
  };

  useEffect(() => {
    if(props?.data?.amount_to_be_collected) {
      setAmount(props?.data?.amount_to_be_collected);
    }
  }, [props?.data])

  const handleClose = () => {
    props.callback();
  };

  return (
      <Dialog
          onClose={handleClose}
          open={props.open}
          scroll={"paper"}
          maxWidth="xl"
      >
        <DialogTitle>
          <Typography fontWeight={'600'}>
            {"Party Amount to be Collected"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{width: '300px'}}>
            <TextField
                label="Enter Amount"
                value={amount}
                type={'number'}
                fullWidth
                onChange={(event) => {
                  setAmount(Number(event.target.value));
                }}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ marginTop: "12px" }}>
          <Button
              variant="contained"
              color={"error"}
              onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
              variant="contained"
              onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default CashCollectedDialog;
