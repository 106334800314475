import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react'
import moment from "moment";

const TransactionListForModule=(props:any)=>{
  return (
    <TableContainer component={Paper}>
    <Table>
        <TableHead>
            <TableRow>
                <TableCell>OrderId</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Refund amount</TableCell>
                <TableCell>Method</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell/>
            </TableRow>
        </TableHead>
        <TableBody>
            {props.data.map((row: any) => (
                <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell scope="row">{row?.order_id}</TableCell>
                    <TableCell align="left">{row?.currency}</TableCell>
                    <TableCell align="left">{row?.amount ? Math.abs(row?.amount/100).toFixed(2) : '---'}</TableCell>
                    <TableCell align="left">{row?.status || '---'}</TableCell>
                    <TableCell align="left">{row?.amount_refunded ? Math.abs(row?.amount_refunded/100).toFixed(2) : '---'}</TableCell>
                    <TableCell align="left">{row?.method}</TableCell>
                    <TableCell align="left">{moment.unix(row?.created_at).format('DD-MM-YYYY hh:mm A')}</TableCell>
                    <TableCell align="left">{row?.long}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer>
);
}

export default TransactionListForModule
