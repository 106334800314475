import React, { useState } from 'react'
import CkMuiDialog from './CkMuiDialog/CkMuiDialog';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { OneMealService } from '../../services/OneMealService';
import { useRecordContext, useRefresh } from 'react-admin';
import EventBus from '../../EventBus';
import { EventBusEventsEnum } from '../../enums/EventBusEventsEnum';
import { CustomSnackbarTypes } from './CustomSnackBar';

interface InputProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}
function UpdateAllowedDishesDialog(props : InputProps) {
    const record = useRecordContext();
    const [allowedDishes, setAllowedDishes] = useState<any>(1);
    const refresh = useRefresh();
    let oneMealService = new OneMealService();

    const handleUpdate = async() => {
        if (allowedDishes && record?.id) {
            const payload = {
                noOfAllowedDishes: allowedDishes,
            }
            oneMealService?.changeAllowedDishes(record?.id as number, payload).then((res) => {
                if (res) {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Updated Successfully",
                        type: CustomSnackbarTypes.SUCCESS,
                    });
                    refresh();
                    props?.setOpen(false);
                }
            })
        } 
    }
  return (
    <CkMuiDialog
        fullWidth
        maxWidth='xs'
        isDialogOpen={props?.open}
        setDialogOpen={props?.setOpen}
        onDialogClose={() => {}}
        title={"Update Allowed Dishes"}
        primaryLabel={"Submit"}
        primaryAction={handleUpdate}
        secondaryLabel={"Cancel"}
        secondaryAction={() => {
            props?.setOpen(false);
        }}
        isLoading={false}
    >
      <Box>
        <FormControl fullWidth>
            <InputLabel id="update_allowed_Dishes">Update Allowed Dishes</InputLabel>
            <Select
                labelId="for_slot"
                id="Select Service"
                label="Select Service"
                value={allowedDishes}
                onChange={(event) => setAllowedDishes(event?.target?.value)}
            >
                {Array.from({ length: 10 }, (_, index) => index + 1).map((count: number) => (
                    <MenuItem key={count} value={count}>
                      {count}
                    </MenuItem>
                  ))}
            </Select>
        </FormControl>
      </Box>
    </CkMuiDialog>
  )
}

export default UpdateAllowedDishesDialog
